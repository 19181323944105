import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { SignupPageComponent } from './signup/singup-page.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup-page/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordNewPasswordComponent } from './reset-password-new-password/reset-password-new-password.component';
import { ResetPasswordNotificationComponent } from './reset-password-notification/reset-password-notification.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { LazyTranslateLoaderModule } from '../entry-point/lazy-translate-loader.module';
import { EmailVerificationDialogComponent } from '../identity/email-verification-dialog/email-verification-dialog.component';
import { SetEmailDialogComponent } from './set-email-dialog/set-email-dialog.component';
import { SetTimeZoneDialogComponent } from './set-time-zone-dialog/set-time-zone-dialog.component';
import { GoogleSigninComponent } from './google-signin/google-signin';
import { RecaptchaModule } from 'ng-recaptcha';

const declarations = [
    LoginPageComponent,
    SignupPageComponent,
    LoginComponent,
    SignupComponent,
    GoogleSigninComponent,
    ResetPasswordPageComponent,
    ResetPasswordComponent,
    ResetPasswordNewPasswordComponent,
    ResetPasswordNotificationComponent,
    SetTimeZoneDialogComponent,
    EmailVerificationDialogComponent,
    SetEmailDialogComponent
];
const entryComponents = [
    LoginComponent,
    SignupComponent,
    EmailVerificationDialogComponent,
    SetEmailDialogComponent,
    SetTimeZoneDialogComponent
];

@NgModule({
    imports: [
        // system
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        RecaptchaModule,
        // permissions
        NgxPermissionsModule.forRoot(),
        // translation
        LazyTranslateLoaderModule,
        // custom
        CustomMaterialModule,
        SharedModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents],
    entryComponents: entryComponents
})
export class IdentityModule {}
