import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostedPage } from '../models/hosted-page';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChargebeeUrlPaymentDialogComponent } from '../chargebee-url-payment-dialog/chargebee-url-payment-dialog.component';
import { CartInfo } from '../models/cart-info';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderProvider } from '../providers/order/order-provider.service';
import { AutoUnsubscribe } from '../../shared/decorators/autoUnsubscribe.decorator';

@Component({
  selector: 'app-chargebee-dialog',
  templateUrl: './chargebee-dialog.component.html',
  styleUrls: ['./chargebee-dialog.component.scss']
})
@AutoUnsubscribe()
export class ChargebeeDialogComponent implements OnInit {

  constructor(private $http: HttpClient,
              private dialog: MatDialog,
              private orderProviderService: OrderProvider,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  hostedPage: HostedPage;
  private componentDestroyed = new Subject();
  private dialogRef;

  ngOnInit(): void {
  }

  openChargebeeCheckoutUrl(item: string){
    this.orderProviderService.getChargebeeCheckoutUrl(item).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => 
      {
        this.hostedPage = response;
        this.dialog.closeAll();
        this.dialogRef = this.dialog.open(ChargebeeUrlPaymentDialogComponent, {
          data: {hostedPageData : response, dataItem: item}
        })
        this.dialogRef.afterClosed().pipe(takeUntil(this.componentDestroyed))
        .subscribe((result) => {
              this.checkStatusHostedPage(this.hostedPage, item);
            });
      }
      );
      
  }

  checkStatusHostedPage(hostedPage: HostedPage, product: string){
    this.orderProviderService.getStatusHostedPage(hostedPage).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => 
      {
        if(response == "Succeeded" || response == "3")
        {
          this.getCart(product);
        }
      });
  }

  getCart(product: string){
      this.orderProviderService.getCart(product).pipe(takeUntil(this.componentDestroyed))
      .subscribe(response => 
        {
          this.upgrade(response.CartGuid); 
        }
        );

  }

  upgrade(cartGuid: string){
    let domain = this.data.domain;
    let applicationContextGuid = this.data.applicationContextGuid;

    this.orderProviderService.postUpgrade(cartGuid,domain, applicationContextGuid).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => 
      {
      }
      );
  }

  ngOnDestroy(){ }
  

}
