<mat-expansion-panel
    class="cb-expansion-panel cb-form-expansion-panel"
    [expanded]="expanded"
>
    <mat-expansion-panel-header
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
    >
        <mat-panel-title class="d-flex align-items-center">
            <span class="icon-collapse-wrap">
                <cb-svg-icon
                    class="icon icon-less"
                    [name]="'collapse-less'"
                ></cb-svg-icon>
                <cb-svg-icon
                    class="icon icon-more"
                    [name]="'collapse-more'"
                ></cb-svg-icon>
            </span>
            <h3 class="form-section-title">
                <!--expansion-panel-title-->
                {{ title }}
                <!--end expansion-panel-title-->
            </h3>
            <cb-help-icon [tooltip]="tooltip" *ngIf="tooltip"></cb-help-icon>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <!--expansion-panel-content-->
    <ng-content></ng-content>
    <!--end expansion-panel-content-->
</mat-expansion-panel>
