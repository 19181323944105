<form [formGroup]="form" novalidate class="cb-rename-page-form">
    <mat-form-field 
        class="cb-page-name-input"
        floatLabel="never"
        (click)="showWarningIfLocked()">
        <input
            matInput
            placeholder="{{ 'SURVEY-EDITOR.PAGES.PAGE-NAME' | translate }}"
            outocomplete="off"
            formControlName="name"
            [maxLength]="32"
        />
    </mat-form-field>
</form>
