<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <form
            [formGroup]="form"
            class="cb-question-item-content"
            style="max-width:100%;"
        >
            <div *ngIf="questionItem.allow_html && !isPrint && !isMobile"
                 [tabIndex]="0" role="textbox" [attr.aria-labelledby]="questionId + questionItem.id" class="rich-editor-container">
            <cb-tiny-rich-editor
                class="cb-rich-editor cb-rich-editor-{{guid}}"
                formControlName="answer_text"
                (ngModelChange)="getTextLengthWithoutHtml($event)"
            >
            </cb-tiny-rich-editor>
            </div>
            <mat-form-field
                class="textarea-wrapper"
                *ngIf="!questionItem.allow_html || isPrint || isMobile"
                appearance="outline"
            >
                <textarea
                    matInput
                    [attr.aria-labelledby]="questionId + questionItem.id"
                    formControlName="answer_text"
                    [class]="getTextareaClassName()"
                    [cols]="questionItem.cols ? questionItem.cols : defaultCols"
                    [rows]="questionItem.rows ? questionItem.rows : defaultRows"
                ></textarea>
            </mat-form-field>
            <span
                class="multiline-characters-info"
                *ngIf="!isPrint &&
                    questionItem.min_length &&
                    questionItem.min_length > filledTextLength
                "
            >
                {{ getToLimitText(questionItem.min_length - filledTextLength) }}
            </span>
            <span
                class="multiline-characters-info"
                [ngClass]="{'over-limit': filledTextLength > questionItem.max_length}"
                *ngIf="!isPrint &&
                        questionItem.max_length
                        && questionItem.max_length - filledTextLength"
            > {{getOverLimitText(questionItem.max_length - filledTextLength)}}
            </span>
        </form>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
