import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import * as infra from '../../infrastructure';

import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { routes } from './routes.module';

import { AppComponent } from './app.component';
import { reducers } from '../store/reducers';
import { effects } from '../store/effects';
import { dataProviders } from '../providers';
import { stores } from '../store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { version } from '../../environments/version';
import { IdentityModule } from '../identity/identity.module';
import { EventLogComponent } from '../../shared/components/event-log/event-log.component';
import { NotFoundPageComponent } from '../not-found/not-found-page.component';
import { transformToCloudfrontUrl } from '../../infrastructure/helpers/cloudfront.helper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomPreloadingStrategy } from './custom-preload-strategy.provider';
import { AdminSharedModule } from '../shared/admin-shared.module';
import { ChargebeeDialogComponent } from '../chargebee-dialog/chargebee-dialog.component';
import { ChargebeeUrlPaymentDialogComponent } from '../chargebee-url-payment-dialog/chargebee-url-payment-dialog.component';
import { ResultDialogComponent } from '../result-dialog/result-dialog.component';
// @ts-ignore
import { CodeEditorModule, CodeEditorService } from '@ngstack/code-editor';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        transformToCloudfrontUrl('assets/i18n/'),
        '.json?v=' + version
    );
}

export function createMonacoBaseUrl(): string {
    const isVirtualDirectory = window['__env'] &&
        window['__env']['virtualDirectory'] !== '' &&
        window['__env']['virtualDirectory'] !== undefined;

    const baseUrl = isVirtualDirectory ? `${window['__env']['virtualDirectory']}/assets/monaco/` : '/assets/monaco/';
    const clearBaseUrl = baseUrl.replace(/\/\//g, '/');
    return clearBaseUrl;
}
@NgModule({
    declarations: [
        AppComponent,
        EventLogComponent,
        NotFoundPageComponent,
        ChargebeeDialogComponent,
        ChargebeeUrlPaymentDialogComponent,
        ResultDialogComponent
    ],
    entryComponents: [
        ChargebeeDialogComponent,
        ChargebeeUrlPaymentDialogComponent,
        ResultDialogComponent
    ],
    imports: [
        // system
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes,
            {
    preloadingStrategy: CustomPreloadingStrategy,
    relativeLinkResolution: 'legacy'
}
        ),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        // custom
        CustomMaterialModule,
        SharedModule,
        AdminSharedModule,
        IdentityModule,
        // translation
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        CodeEditorModule.forRoot({
            baseUrl: createMonacoBaseUrl()
        })
    ],
    providers: [
        ...stores,
        ...dataProviders,
        ...infra.providers,
        CustomPreloadingStrategy
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
