<div class="pricing-dialog">
  <div class="pricing-plan basic-plan">
    <div class="plan-header">
      <h2>{{'UPGRADE-ACCOUNT-DIALOG.BASIC' | translate}}</h2>
      <h3>$800</h3>
      <p>{{'UPGRADE-ACCOUNT-DIALOG.YEARLY-1-USER' | translate}}</p>
      <a href="mailto:sales@checkbox.com" class="cta-button">{{'UPGRADE-ACCOUNT-DIALOG.TALK-TO-SALES' | translate}}</a>
    </div>
    <div class="plan-details">
      <ul>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-SURVEYS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-STYLE-TEMPLATES' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.ADVANCED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.EMAILS-10K' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.HOSTING-US' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SUPPORT-ONLINE' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.CUSTOM-BRANDING' | translate}}</li>
      </ul>
      <a class="learn-more" href="https://www.checkbox.com/pricing-cloud#details" target="_blank">{{'UPGRADE-ACCOUNT-DIALOG.LEARN-MORE' | translate}}</a>
    </div>
  </div>
  <div class="pricing-plan advanced-plan">
    <div class="plan-header">
      <h2>{{'UPGRADE-ACCOUNT-DIALOG.ADVANCED' | translate}}</h2>
      <h3>$3,500</h3>
      <p>{{'UPGRADE-ACCOUNT-DIALOG.YEARLY-5-USERS' | translate}}</p>
      <a href="mailto:sales@checkbox.com" class="cta-button">{{'UPGRADE-ACCOUNT-DIALOG.TALK-TO-SALES' | translate}}</a>
    </div>
    <div class="plan-details">
      <ul>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-SURVEYS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-STYLE-TEMPLATES' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.ADVANCED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.EMAILS-25K' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SUPPORT-EMAIL' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SMS-CAMPAIGNS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.MULTI-LANGUAGE' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.HOSTING-ALL' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SSO' | translate}}</li>
      </ul>
      <a class="learn-more" href="https://www.checkbox.com/pricing-cloud#details" target="_blank">{{'UPGRADE-ACCOUNT-DIALOG.LEARN-MORE' | translate}}</a>
    </div>
  </div>
  <div class="pricing-plan enterprise-plan">
    <div class="plan-header">
      <h2>{{'UPGRADE-ACCOUNT-DIALOG.ENTERPRISE' | translate}}</h2>
      <img src="https://cdn.prod.website-files.com/6373a53c683ade695ef0f541/6399f248506e2937314ab555_Group%20315.svg" alt="Enterprise">
      <a href="mailto:sales@checkbox.com" class="cta-button">{{'UPGRADE-ACCOUNT-DIALOG.TALK-TO-SALES' | translate}}</a>
    </div>
    <div class="plan-details">
      <ul>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-SURVEYS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.UNLIMITED-STYLE-TEMPLATES' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.ADVANCED-QUESTIONS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.EMAILS-OVER-25K' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SMS-CAMPAIGNS' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.MULTI-LANGUAGE' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.HOSTING-ALL' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.DEDICATED-HOSTING' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SSO' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.USE-OWN-DOMAIN' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.BAA' | translate}}</li>
        <li><span class="checkmark"></span>{{'UPGRADE-ACCOUNT-DIALOG.SUPPORT-ENTERPRISE' | translate}}</li>
      </ul>
      <a class="learn-more" href="https://www.checkbox.com/pricing-cloud#details" target="_blank">{{'UPGRADE-ACCOUNT-DIALOG.LEARN-MORE' | translate}}</a>
    </div>
  </div>
</div>
