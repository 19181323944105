<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}" #signature>
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <div class="cb-question-item-content">
            <p *ngIf="!showCanvas"
                [innerHTML]="
                    questionItem.answer?.file?.file_name ? (questionItem.answer?.file?.file_name | keepHtml) : (questionItem?.file_name ?? '')
                "
            ></p>
            <div class="signature-pad-container">
                <signature-pad *ngIf="showCanvas"
                    [options]="signaturePadOptions"
                    (onEndEvent)="drawComplete()"
                    #sp
                ></signature-pad>
            </div>
            <button mat-button (click)="clearSignaturePad()">{{surveyText.SignatureClear}}</button>
        </div>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
