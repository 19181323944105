import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResourcesConsts } from '../../consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';
import { UserApplicationSettings } from './user-application-settings/user-application-settings.model';
import {
    CaptchaSettings,
    SurveyApplicationSettingsPreferences,
    SurveyApplicationSettingsPreferencesModel
} from './survey-application-settings/survey-application-settings.model';
import {
    AppTimeZone,
    PreferenceApplicationSettings,
    PreferenceApplicationSettingsModel,
    SamlSettings
} from './preferences-application-settings/perferences-application-settings.model';
import {
    FileUploadApplicationSettingsModel,
    SurveyApplicationSettings,
    SurveyApplicationSettingsModel
} from './survey-application-settings/survey-application-settings.model';
import {
    ExportResponsesSettings,
    ExportResponsesSettingsModel,
    ReportApplicationSettings,
    ReportApplicationSettingsModel
} from './report-application-settings/report-application-settings.model';
import { Observable } from 'rxjs';
import {
    CompanyProfile,
    EmailInvitationSettingsModel,
    PhoneNumberModel,
    SmsInvitationSettingsModel,
    SmsMessagingStatusModel,
    InvitationLinkSettingsModel
} from './messaging-application-settings/messaging-application-settings.model';
import { SkipInterceptor } from '../../../infrastructure/consts/interceptors.const';


@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsProvider {
    constructor(private $http: HttpClient) {
    }

    getUserApplicationSettings() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_CONTACT
        );
        return this.$http.get(url).pipe(
            map((data: any) => new UserApplicationSettings(data))
        );
    }


    getUserAdminCount() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_CONTACT_ADMIN_COUNT
        );
        return this.$http.get<number>(url);
    }

    getContactPerformanceSetting() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_CONTACT_PERFORMANCE
        );
        return this.$http.get(url);
    }

    getValidationTextApplicationSettings(lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_VALIDATION_TEXT,
            lang
        );
        return this.$http
            .get(url, {});
    }

    saveValidationTextApplicationSettings(text, lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_VALIDATION_TEXT,
            lang
        );
        return this.$http
            .put(url, text);
    }

    getSurveyTextApplicationSettings(lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_SURVEY_TEXT,
            lang
        );
        return this.$http
            .get(url, {});
    }

    saveSurveyTextApplicationSettings(text, lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_SURVEY_TEXT,
            lang
        );
        return this.$http
            .put(url, text);
    }

    saveUserApplicationSettings(systemSettings: UserApplicationSettings) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_CONTACT
        );
        return this.$http.put(url, systemSettings.toJSON()).pipe(
            map((data: any) => new UserApplicationSettings(data))
        );
    }

    getSystemPreferences() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_PREFERENCES
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PreferenceApplicationSettingsModel(data))
        );
    }

    saveSystemPreferences(systemSettings: PreferenceApplicationSettings) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_PREFERENCES
        );
        return this.$http.put(url, systemSettings).pipe(
            map((data: any) => new PreferenceApplicationSettingsModel(data))
        );
    }

    getTimeZones() : Observable<AppTimeZone[]> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_TIME_ZONES
        );
        return this.$http.get<AppTimeZone[]>(url);
    }

    getSamlSettings() : Observable<SamlSettings> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_SAML
        );
        return this.$http.get<SamlSettings>(url);
    }

    setSamlSettings(data: SamlSettings) : Observable<SamlSettings> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_SAML
        );
        return this.$http.put<SamlSettings>(url, data);
    }

    isSamlEnabled() : Observable<boolean> {
        const url: string = ResourcesConsts.APPLICATION_SETTINGS_IS_SAML_ENABLED;
        return this.$http.get<boolean>(url);
    }

    getLegacyTimeZoneName(offsetInHours: number): string {
        if (offsetInHours == null)
            return '';

        switch (offsetInHours) {
            case -12: return '(GMT-12:00) International Date Line West';
            case -11: return '(GMT-11:00) Midway Islands, Samoan Islands';
            case -10: return '(GMT-10:00) Hawaii';
            case -9: return '(GMT-09:00) Alaska';
            case -8: return '(GMT-08:00) Pacific Time (USA, Canada)';
            case -7: return '(GMT-07:00) Mountain Time (USA, Canada)';
            case -6: return '(GMT-06:00) Central time (USA, Canada)';
            case -5: return '(GMT-05:00) Eastern time (USA, Canada)';
            case -4.5: return '(GMT-04:30) Caracas';
            case -4: return '(GMT-04:00) Atlantic Time (Canada)';
            case -3.5: return '(GMT-03:30) Newfoundland';
            case -3: return '(GMT-03:00) Brasilia';
            case -2: return '(GMT-02:00) Mid-Atlantic';
            case -1: return '(GMT-01:00) Azores';
            case 0: return '(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London';
            case 1: return '(GMT+01:00) Amsterdam, Berlin, Bern, Rom, Stockholm, Vienna';
            case 2: return '(GMT+02:00) Athens, Istanbul, Minsk';
            case 3: return '(GMT+03:00) Moscow, St. Petersburg, Kaliningrad, Kuwait, Nairobi';
            case 3.5: return '(GMT+03:30) Teheran';
            case 4: return '(GMT+04:00) Abu Dhabi, Muscat';
            case 4.5: return '(GMT+04:30) Kabul';
            case 5: return '(GMT+05:00) Islamabad, Karachi, Tasjkent';
            case 5.5: return '(GMT+05:30) Kolkata, Chennai, Mumbai, New Delhi';
            case 5.75: return '(GMT+05:45) Kathmandu';
            case 6: return '(GMT+06:00) Astana, Dhaka';
            case 6.5: return '(GMT+06:30) Rangoon';
            case 7: return '(GMT+07:00) Bangkok, Hanoi, Jakarta';
            case 8: return '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi';
            case 9: return '(GMT+09:00) Osaka, Sapporo, Tokyo';
            case 9.5: return '(GMT+09:30) Adelaide';
            case 10: return '(GMT+10:00) Canberra, Melbourne, Sydney';
            case 11: return '(GMT+11:00) Solomon Islands, New Caledonia';
            case 12: return '(GMT+12:00) Fiji, Magadan, Wellington';
            case 13: return '(GMT+13:00) Nuku\'alofa';
            default: return '(GMT' + (offsetInHours > 0 ? '+' : '') + offsetInHours + ':00)';
        }
    }

    getSurveyApplicationSettings(): Observable<SurveyApplicationSettings> {
        const url: string = String.Format(ResourcesConsts.SURVEY_SYSTEM_SETTINGS);
        return this.$http.get<SurveyApplicationSettings>(url).pipe(
            map((data: any) => new SurveyApplicationSettingsModel(data))
        );
    }

    getCaptchaSettings(): Observable<CaptchaSettings> {
        const url: string = String.Format(ResourcesConsts.CAPTCHA_SETTINGS);
        return this.$http.get<CaptchaSettings>(url);
    }

    saveCaptchaSettings(captcha) {
        const url: string = String.Format(ResourcesConsts.CAPTCHA_SETTINGS);
        return this.$http.put(url, captcha);
    }

    getFileUploadSettings() {
        const url: string = String.Format(ResourcesConsts.SURVEY_SYSTEM_SETTINGS);
        return this.$http.get(url).pipe(
            map(
                (data: any) =>
                    new FileUploadApplicationSettingsModel(
                        data.file_upload_item_settings
                    )
            )
        );
    }

    saveSurveyApplicationSettings(systemSettings: SurveyApplicationSettings) {
        const url: string = String.Format(ResourcesConsts.SURVEY_SYSTEM_SETTINGS);
        return this.$http.put(url, systemSettings).pipe(
            map((data: any) => new SurveyApplicationSettingsModel(data))
        );
    }

    getNewJWTToken() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_CONTACT_NEW_JWT_TOKEN
        );
        return this.$http
            .post(url, {});
    }

    getPrivacyInformationText(lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_PRIVACY_INFORMATION,
            lang
        );
        return this.$http
            .get(url, {});
    }

    savePrivacyInformationText(text, lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_PRIVACY_INFORMATION,
            lang
        );
        return this.$http
            .put(url, text);
    }

    getReportSettings(): Observable<ReportApplicationSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_REPORT
        );
        return this.$http.get(url).pipe(
            map((data: any) => new ReportApplicationSettingsModel(data))
        );
    }

    saveReportSettings(
        reportSettings: ReportApplicationSettings
    ): Observable<ReportApplicationSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_REPORT
        );
        return this.$http.put(url, reportSettings).pipe(
            map((data: any) => new ReportApplicationSettingsModel(data))
        );
    }

    getExportSettings(): Observable<ExportResponsesSettings> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_RESPONSE_EXPORT
        );
        return this.$http.get<ExportResponsesSettings>(url);
    }

    updateExportSettings(settings: ExportResponsesSettingsModel) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_RESPONSE_EXPORT
        );
        return this.$http
            .put(url, settings.toJSON());
    }

    getEmailMessagingSettings(): Observable<EmailInvitationSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_EMAIL
        );
        return this.$http.get(url).pipe(
            map((data: EmailInvitationSettingsModel) => {
                if (data && data.company_profiles) {
                    data.company_profiles = data.company_profiles.map(c => new CompanyProfile(c));
                }
                return data;
            })
        );
    }

    saveEmailMessagingSettings(settings: EmailInvitationSettingsModel) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_EMAIL
        );
        return this.$http.put(url, settings).pipe(
            map((data: EmailInvitationSettingsModel) => {
                if (data && data.company_profiles) {
                    data.company_profiles = data.company_profiles.map(c => new CompanyProfile(c));
                }
                return data;
            })
        );
    }

    getSmsSettings(): Observable<SmsInvitationSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_SMS
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SmsInvitationSettingsModel(data))
        );
    }

    saveSmsSettings(settings: SmsInvitationSettingsModel) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_SMS
        );
        return this.$http.put(url, settings.toJSON()).pipe(
            map((data: any) => new SmsInvitationSettingsModel(data))
        );
    }

    getSmsMessagingStatus(): Observable<SmsMessagingStatusModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_SMS_STATUS
        );
        return this.$http.get(url).pipe(
            map((data: any) => new SmsMessagingStatusModel(data))
        );
    }

    provisionPhoneNumber(phoneNumber: PhoneNumberModel) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_SMS_PROVISION_PHONE_NUMBER
        );
        return this.$http.post(url, phoneNumber.toJSON());
    }

    getAvailablePhoneNumbers(countryCode: string, areaCode: string, contains: string)
        : Observable<Array<PhoneNumberModel>> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_MESSAGING_SMS_AVAILABLE_PHONE_NUMBERS
        );

        const params = new HttpParams()
            .set('country_code', countryCode)
            .set('area_code', areaCode)
            .set('contains', contains);

        return this.$http.get(url, { params: params }).pipe(
            map((data: Array<PhoneNumberModel>) => data.map(p => new PhoneNumberModel(p)))
        );
    }

    getCompanyProfiles(): Observable<Array<CompanyProfile>> {
        const url: string = String.Format(
            ResourcesConsts.COMPANY_PROFILES
        );
        return this.$http.get(url).pipe(
            map((data: Array<CompanyProfile>) => data.map(company => new CompanyProfile(company)))
        );
    }

    setCompanyProfiles(companyProfiles: Array<CompanyProfile>): Observable<Array<CompanyProfile>> {
        const url: string = String.Format(
            ResourcesConsts.COMPANY_PROFILES
        );
        return this.$http.put(url, companyProfiles).pipe(
            map((data: Array<CompanyProfile>) => data.map(company => new CompanyProfile(company)))
        );
    }

    createCompanyProfile(company: CompanyProfile) {
        const url: string = String.Format(
            ResourcesConsts.COMPANY_PROFILES
        );
        return this.$http
            .post(url, company.toJSON());
    }

    editCompanyProfile(company: CompanyProfile, id) {
        const url: string = String.Format(
            ResourcesConsts.COMPANY_PROFILE,
            id
        );
        return this.$http
            .put(url, company.toJSON());
    }

    removeCompanyProfile(id: number) {
        const url: string = String.Format(
            ResourcesConsts.COMPANY_PROFILE,
            id
        );
        return this.$http
            .delete(url);
    }

    getInvitationLinkSettings(): Observable<InvitationLinkSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_INVITATION_LINK
        );
        return this.$http.get(url).pipe(
            map((data: any) => new InvitationLinkSettingsModel(data))
        );
    }

    getSurveySettingsPreferences(): Observable<SurveyApplicationSettingsPreferences> {
        const url: string = String.Format(ResourcesConsts.SURVEY_SETTINGS_PREFERENCES);
        return this.$http.get<SurveyApplicationSettingsPreferences>(url);
    }
}
