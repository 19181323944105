import { FormGroup, FormControl } from '@angular/forms';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    HostListener, ViewChild, ElementRef, OnDestroy, AfterViewInit
} from '@angular/core';
import { SliderItem } from '../../../models/survey-items/question-items/sliderItem';
import { StorageProvider } from '../../../../infrastructure/providers';
import { String } from 'typescript-string-operations-ng4';
import { environment } from '../../../../environments/environment';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { EnvironmentProvider } from '../../../../infrastructure/providers/environment.provider';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { WINDOW_WIDTH } from '../../../../infrastructure/consts/window-width';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import * as _ from 'lodash';
import { ItemOption } from '../../../models/survey-items/question-items/itemOption';

@Component({
    selector: 'cb-slider-item-preview',
    templateUrl: './slider-item-preview.component.html',
    styleUrls: ['./slider-item-preview.component.scss']
})
@AutoUnsubscribe()
export class SliderPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: SliderItem;
    @Input() isMatrix: boolean;
    @Output() updated = new EventEmitter<any>();
    @ViewChild('imageSlider') elementView: ElementRef;
    form: FormGroup;
    mobileWidth = WINDOW_WIDTH.mobileWidth;
    isMobile = false;
    imageWidth = 100;
    sliderWidth = 500;
    allItemsWidth = 0;
    min_value: number;
    max_value: number;
    step: number;
    value: number;
    activeLabel = '';
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    private accountRegex = /\{account\}/gim;
    currentSliderHeight;
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;
    enabled_choices: ItemOption[] = [];

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < this.mobileWidth;
    }

    constructor(
        private storageProvider: StorageProvider,
        private environmentProvider: EnvironmentProvider,
        private elementRef: ElementRef,
        private printService: PrintService
        ) { }

    ngOnInit() {
        this.mobileWidth = this.isMatrix ? WINDOW_WIDTH.tabletWidth : WINDOW_WIDTH.mobileWidth;

        const isNumericSlider = this.questionItem.value_type === 'NumberRange';
        this.min_value = isNumericSlider && this.questionItem.min_value != null
            ? this.questionItem.min_value
            : 1;

        if (isNumericSlider) {
            this.questionItem.default_value = this.questionItem.default_value != null
                ? this.questionItem.default_value
                : this.min_value;
        } else {
            this.enabled_choices = getEnabledChoices(this.questionItem.choices);
            const defaultChoiceIndex = _.findIndex(this.enabled_choices, ['is_default', true]);
            this.questionItem.default_value = defaultChoiceIndex > -1
                ? defaultChoiceIndex + 1
                : 1;
        }

        this.max_value = this.questionItem.max_value
            ? this.questionItem.max_value
            : this.enabled_choices.length;
        this.step = this.questionItem.step_size
            ? this.questionItem.step_size
            : 1;
        this.value = this.questionItem.value
            ? this.questionItem.value
            : this.questionItem.default_value;
        this.sliderWidth = this.questionItem.width || this.sliderWidth;
        this.isMobile = window.innerWidth < this.mobileWidth;
        this.initUrlsForUploadedImages();
        this.setImageWidth();
        this.createFormGroup();
    }

    ngAfterViewInit() {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elementRef);
        }
    }

    public onSliderLabelClick(index: number): void {
        this.form.get('value').setValue(index + 1);
    }

    public getSliderTicks(num: number): any[] {
        const ticks = Math.ceil((this.max_value - this.min_value + 1) / this.step);
        return Array(ticks);
    }

    public getSliderActiveLabel(choice) {
        this.activeLabel = choice.text;
    }

    public onImageClick(index: number): void {
        this.form.get('value').setValue(index + 1);
    }

    public getImageUrl(choice): string {
        return choice.image && choice.image.file_url ? choice.image.file_url : '';
    }

    public get sliderValue() {
        return this.form.controls['value'].value;
    }

    private createFormGroup(): void {
        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            value: new FormControl(
                this.questionItem.value
                    ? this.questionItem.value
                    : this.questionItem.default_value
            )
        });
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        if (this.isMatrix) {
            this.updated.emit(this.form.value);
        }
    }

    public resetForm() {
        this.form.get('value').setValue(this.min_value);
        this.value = this.min_value;
    }

    private initUrlsForUploadedImages() {
        this.enabled_choices.forEach(choice => {
            if (choice.image && !choice.image.file_url) {
                this.storageProvider.getAccount().subscribe(account => {
                    choice.image.file_url = this.getUrlForUploadedImage(
                        choice.image.id,
                        account
                    );
                });
            }
        });
    }

    private getUrlForUploadedImage(id, account) {
        const url = String.Format('/{account}/files/{0}/content', id);
        if (environment.name === 'server') {
            return (
                this.rootApiUrl +
                url.replace(this.accountRegex, '').replace('//', '/')
            );
        } else {
            return this.rootApiUrl + url.replace(this.accountRegex, account);
        }
    }

    private setImageWidth(width = this.sliderWidth) {
        if (this.enabled_choices && this.enabled_choices.length) {
            this.imageWidth = 100;
            const numberOfItems = this.enabled_choices.length - 1;

            this.allItemsWidth = this.imageWidth * numberOfItems;
            while (this.allItemsWidth >= width) {
                this.imageWidth -= 10;
                this.allItemsWidth = this.imageWidth * numberOfItems;
            }
        }
    }

    private get rootApiUrl(): string {
        return this.environmentProvider.apiUrl;
    }

    public getSliderSizeStyle(choicesLength): {} {
        if (this.questionItem.value_type === 'Image') {
            const size = choicesLength === 1 ? 100 : 100 - 100 / choicesLength;
            if (this.isMobile) {
                return {
                    'height.%': size
                };
            }
            return {
                'width.%': size
            };
        }
        return {};
    }

    ngOnDestroy() {}
}
