export interface BaseSmsMessage {
    type: 'single' | 'batch';
    text: string;
    message_type: MessageType;
}

export interface SingleSmsMessage extends BaseSmsMessage {
    to_phone_number: string;
}

export interface BatchSmsMessage extends BaseSmsMessage {
    to_phone_numbers: string[];
}

export enum MessageType {
    SMS = 0,
    WhatsApp = 1
}