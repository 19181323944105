import { Component, OnInit,Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OrderProvider } from '../providers/order/order-provider.service';
import { MatDialog } from '@angular/material/dialog';
import { HostedPage } from '../models/hosted-page';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../shared/decorators/autoUnsubscribe.decorator';

@Component({
  selector: 'app-chargebee-url-payment-dialog',
  templateUrl: './chargebee-url-payment-dialog.component.html',
  styleUrls: ['./chargebee-url-payment-dialog.component.scss']
})
@AutoUnsubscribe()
export class ChargebeeUrlPaymentDialogComponent implements OnInit, OnDestroy {

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private orderProviderService: OrderProvider
  ) { }

  url: string = 'https://checkbox.chargebee.com/pages/v3/ATZO8sDyMINhzhObljQn7AzKDW4hRAmG/';;
  urlMap: SafeResourceUrl | undefined;
  private componentDestroyed = new Subject();
  private dialogRef;

  ngOnInit(): void {
    this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.hostedPageData.Url);
    this.dialog.afterAllClosed.subscribe((response => {
      this.checkStatusHostedPage(this.data.hostedPageData, this.data.dataItem);
    }))
  }


  checkStatusHostedPage(hostedPage: HostedPage, product: string){
    this.orderProviderService.getStatusHostedPage(hostedPage).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => 
      {
        if(response.toLowerCase() == "succeeded" || response == "3")
        {
          this.getCart(product);
        }
      });
  }

  getCart(product: string){
      this.orderProviderService.getCart(product).pipe(takeUntil(this.componentDestroyed))
      .subscribe(response => 
        {
          this.upgrade(response.CartGuid); 
        }
        );

  }

  upgrade(cartGuid: string){
    let domain = this.data.domain;
    let applicationContextGuid = this.data.applicationContextGuid;

    this.orderProviderService.postUpgrade(cartGuid,domain, applicationContextGuid).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => 
      {
        let result = "Upgrade Success!"
        this.dialogRef = this.dialog.open(ChargebeeUrlPaymentDialogComponent, {
          data: {result: result}
        })
      }
      );
  }

  ngOnDestroy(){ }

}
