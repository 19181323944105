<cb-spinner [show]="isLoading"></cb-spinner>
<div class="cb-system-settings">
    <form [formGroup]="form" novalidate>
        <mat-expansion-panel
            class="cb-page-panel cb-expansion-panel"
            [expanded]="true"
        >
            <mat-expansion-panel-header
                collapsedHeight="48px"
                expandedHeight="48px"
            >
                <mat-panel-title class="d-flex align-items-center">
                    <span class="icon-collapse-wrap">
                        <cb-svg-icon
                            class="icon icon-less"
                            name="collapse-less"
                        ></cb-svg-icon>
                        <cb-svg-icon
                            class="icon icon-more"
                            name="collapse-more"
                        ></cb-svg-icon>
                    </span>
                    <span class="title--panel">{{
                        'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-SURVEYS' | translate
                        }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="subsection">
                <p class="subsection__description">
                    {{
                    'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-SURVEYS-INSTRUCTIONS'
                        | translate
                    }}
                </p>

                <mat-form-field>
                    <input
                        type="text"
                        matInput
                        formControlName="restoreSurveyName"
                        [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="
                            onAutocompleteSelected($event.option.value, true)
                        "
                    >
                        <mat-option
                            *ngFor="let survey of filteredObservableSurveys | async"
                            [value]="survey"
                        >
                            {{ survey.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <br>
                <mat-checkbox
                    class="restore-responses"
                    formControlName="restoreSurveyResponses"
                    checked
                >
                    {{
                    'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-SURVEYS-RESPONSES'
                        | translate
                    }}
                </mat-checkbox>
                <br>
                <button
                    [disabled]="isLoading || !isValidSurvey"
                    class="mat-raised-button"
                    (click)="restoreSurvey()"
                >
                    {{
                    'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-SURVEYS-BUTTON'
                        | translate
                    }}
                </button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel
            class="cb-page-panel cb-expansion-panel"
            [expanded]="true"
        >
            <mat-expansion-panel-header
                collapsedHeight="48px"
                expandedHeight="48px"
            >
                <mat-panel-title class="d-flex align-items-center">
                    <span class="icon-collapse-wrap">
                        <cb-svg-icon
                            class="icon icon-less"
                            name="collapse-less"
                        ></cb-svg-icon>
                        <cb-svg-icon
                            class="icon icon-more"
                            name="collapse-more"
                        ></cb-svg-icon>
                    </span>
                    <span class="title--panel">{{
                        'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-RESPONSES' | translate
                        }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="subsection">
                <p class="subsection__description">
                    {{
                    'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-RESPONSES-INSTRUCTIONS'
                        | translate
                    }}
                </p>

                <mat-form-field>
                    <input
                        type="text"
                        matInput
                        formControlName="restoreResponsesName"
                        [matAutocomplete]="autoResponse"
                    />
                    <mat-autocomplete
                        #autoResponse="matAutocomplete"
                        (optionSelected)="
                            onAutocompleteSelected($event.option.value)
                        "
                    >
                        <mat-option
                            *ngFor="let response of filteredObservableResponses | async"
                            [value]="response"
                        >
                            {{ response.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <br>
               <!-- <div class="form-fields-group">
                    <cb-date-time-picker
                        placeholder="{{
                            'SURVEY-SETTINGS.LIMITS.START-DATE' | translate
                        }}"
                        formControlName="startDate"
                    ></cb-date-time-picker>
                    <cb-date-time-picker
                        placeholder="{{
                            'SURVEY-SETTINGS.LIMITS.COMPLETION-DATE' | translate
                        }}"
                        formControlName="endDate"
                        [minDate]="form.controls['startDate'].value"
                    ></cb-date-time-picker>
                </div> -->
                <button
                    [disabled]="isLoading || !isValidResponse"
                    class="mat-raised-button"
                    ngxClipboard
                    (click)="onRestoreSurveyResponses()"
                >
                    {{
                    'SYSTEM_SETTINGS.RESTORE-DATA.RESTORE-RESPONSES-BUTTON'
                        | translate
                    }}
                </button>
            </div>
        </mat-expansion-panel>
    </form>
</div>
