import { Component, OnInit, Inject } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss']
})
export class ResultDialogComponent implements OnInit {
  result: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    
   }

  ngOnInit(): void {
    this.result = this.data.result;
  }

}
