<div class="search-group">
    <mat-form-field
        *ngIf="displaySearchField"
        class="example-full-width"
        floatLabel="never"
    >
        <input matInput [hidden]="true" />
        <ng-content></ng-content>
        <button mat-icon-button matSuffix mat-button>
            <cb-svg-icon class="icon" [name]="'search'"></cb-svg-icon>
        </button>
    </mat-form-field>
    <button
        mat-icon-button
        *ngIf="!displaySearchField"
        (click)="displaySearchFieldOnClick()"
    >
        <cb-svg-icon class="icon" [name]="'search'"></cb-svg-icon>
    </button>
</div>
