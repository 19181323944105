<div class="cb-url">
    <span class="cb-action-item">
        <span
            class="btn-narrow mat-icon-button mat-icon-button--share"
            mat-icon-button
        >
            <a target="_blank"
               [href]="urlLink + (attr ? attr : '')">
                <cb-svg-icon
                    width="16"
                    height="16"
                    class="icon"
                    [name]="'open_in_new'"
                ></cb-svg-icon>
            </a>
        </span>
    </span>
    <span class="cb-action-item ">
        <button
            class="btn-narrow mat-icon-button--share"
            mat-icon-button
            ngxClipboard
            [cbContent]="urlLink + (attr ? attr : '')"
            (cbOnSuccess)="urlCopied()"
            matTooltip="{{
                'SHARED.COPY-CLIPBOARD' | translate
            }}"
        >
            <a>
                <cb-svg-icon
                    width="16"
                    height="16"
                    class="icon"
                    [name]="'clone'"
                ></cb-svg-icon>
            </a>
        </button>
    </span>
    {{ host && host.length ? host + '/' : urlLink }}
</div>
