import { SharedService } from '../../../infrastructure/services/shared.service';
import {
    SurveysPageTypes,
    SurveyReportType,
    SurveyExpressionsSourceType
} from '../../../infrastructure/consts/surveys.consts';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    HostListener,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    OnDestroy
} from '@angular/core';
import { RespondentSSS, SurveyPage } from '../../../app-admin/models';
import {
    SurveyActionType,
    SurveyQuestionType,
    EditorSurveyQuestionType,
    SurveyDisplayType
} from '../../../infrastructure/consts/surveys.consts';
import * as _ from 'lodash';
import { ResponsePageAction } from '../../../app-take-survey/models';
import { ValidationObject } from '../../../infrastructure/models/validation-object.model';
import { TakeSurveyData } from '../../../infrastructure/consts/take-survey.consts';
import { LanguageItemModel } from '../../../infrastructure/models/survey-settings.model';
import { Meta } from '@angular/platform-browser';
import { SurveyDefaultText } from '../../../infrastructure/consts/surveyDefaultText';
import { SinglelinesPreviewItemComponent } from '../question-components/singlelines-item-preview/singlelines-item-preview.component';
import { MultilinesPreviewItemComponent } from '../question-components/multilines-item-preview/multilines-item-preview.component';
import { RadioButtonsPreviewItemComponent } from '../question-components/radiobuttons-item-preview/radiobuttons-item-preview.component';
import { CheckboxesPreviewItemComponent } from '../question-components/checkboxes-item-preview/checkboxes-item-preview.component';
import { DropDownListPreviewItemComponent } from '../question-components/dropdownlist-item-preview/dropdownlist-item-preview.component';
import { CustomSourceDropDownListPreviewItemComponent } from '../question-components/custom-source-dropdownlist-item-preview/custom-source-dropdownlist-item-preview.component';
import { MatrixPreviewItemComponent } from '../question-components/matrix-item-preview/matrix-item-preview.component';
import { ContactFormPreviewItemComponent } from '../question-components/contact-form-item-preview/contact-form-item-preview.component';
import { RatingScalePreviewItemComponent } from '../question-components/rating-scale-item-preview/rating-scale-item-preview.component';
import { SliderPreviewItemComponent } from '../question-components/slider-item-preview/slider-item-preview.component';
import { MaxDiffPreviewItemComponent } from '../question-components/max-diff-item-preview/max-diff-item-preview.component';
import { RankOrderPreviewItemComponent } from '../question-components/rankorder-item-preview/rankorder-item-preview.component';
import { RatingPreviewItemComponent } from '../question-components/rating-item-preview/rating-item-preview.component';
// tslint:disable-next-line:max-line-length
import { NetPromoterScorePreviewItemComponent } from '../question-components/net-promoter-score-item-preview/net-promoter-score-item-preview.component';
import { FileUploadPreviewItemComponent } from '../question-components/file-upload-item-preview/file-upload-item-preview.component';
import { SignaturePreviewItemComponent } from '../question-components/signature-item-preview/signature-item-preview.component';
import { String } from 'typescript-string-operations-ng4';
import { ImageChoicePreviewItemComponent } from '../question-components/image-choice-item-preview/image-choice-item-preview.component';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { debounceWithFirst } from '../../../infrastructure/rxjs-custom-operators/debounce-with-first.operator';

@Component({
    selector: 'cb-survey-page',
    templateUrl: './survey-page.component.html',
    styleUrls: ['./survey-page.component.scss']
})
@AutoUnsubscribe()
export class SurveyPageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() page: SurveyPage;
    @Input() pages: SurveyPage[];
    @Input() isPrint: boolean;
    @Input() validationMessages: ValidationObject[];
    @Input() activeLanguage: LanguageItemModel;
    @Input() respondentSettings: RespondentSSS;
    @Input() disableButtons: boolean;
    @Input() isFromAdmin: boolean;
    @Input() isTakeSurvey = true;
    @Output() transitionClicked = new EventEmitter<any>();
    @Output() restartSurvey = new EventEmitter<boolean>();

    // references to child previews, they are used for resetting form
    @ViewChildren(SinglelinesPreviewItemComponent) singleLines: QueryList<
        SinglelinesPreviewItemComponent
    >;
    @ViewChildren(MultilinesPreviewItemComponent) multiLines: QueryList<
        MultilinesPreviewItemComponent>;
    @ViewChildren(RadioButtonsPreviewItemComponent) radioButtons: QueryList<RadioButtonsPreviewItemComponent>;
    @ViewChildren(CheckboxesPreviewItemComponent) checkboxes: QueryList<CheckboxesPreviewItemComponent>;
    @ViewChildren(DropDownListPreviewItemComponent) dropDowns: QueryList<DropDownListPreviewItemComponent>;
    @ViewChildren(CustomSourceDropDownListPreviewItemComponent) customDropDowns: QueryList<CustomSourceDropDownListPreviewItemComponent>;
    @ViewChildren(MatrixPreviewItemComponent) matrixes: QueryList<MatrixPreviewItemComponent>;
    @ViewChildren(ContactFormPreviewItemComponent) contactForms: QueryList<ContactFormPreviewItemComponent>;
    @ViewChildren(RatingScalePreviewItemComponent) ratingScales: QueryList<RatingScalePreviewItemComponent>;
    @ViewChildren(SliderPreviewItemComponent) sliders: QueryList<SliderPreviewItemComponent>;
    @ViewChildren(MaxDiffPreviewItemComponent) maxDiffs: QueryList<
        MaxDiffPreviewItemComponent
    >;
    @ViewChildren(RankOrderPreviewItemComponent) rankOrders: QueryList<
        RankOrderPreviewItemComponent
    >;
    @ViewChildren(RatingPreviewItemComponent) ratings: QueryList<
        RatingPreviewItemComponent>;
    @ViewChildren(NetPromoterScorePreviewItemComponent) scores: QueryList<NetPromoterScorePreviewItemComponent>;
    @ViewChildren(FileUploadPreviewItemComponent) files: QueryList<FileUploadPreviewItemComponent>;
    @ViewChildren(SignaturePreviewItemComponent) signatures: QueryList<SignaturePreviewItemComponent>;
    @ViewChildren(ImageChoicePreviewItemComponent) imageChoice: QueryList<ImageChoicePreviewItemComponent>;

    sumTotalError = 'SumTotalError';
    minMaxError = 'MinMaxError';
    actionType = SurveyActionType;
    questionType = SurveyQuestionType;
    displayType = SurveyDisplayType;
    transitionType = ResponsePageAction;
    reportType = SurveyReportType;
    surveyPageType = SurveysPageTypes;
    influencingItems = [];
    questionItemTypes = [];
    itemsCounter: number;
    surveyText = SurveyDefaultText;
    validationText;
    errorTranslationPrefix = 'VALIDATION.';
    private updateConditionsStream = new Subject();
    private componentDestroyed = new Subject();

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private meta: Meta
    ) {}

    @HostListener('window:popstate', ['$event'])
    onPopState() {
        if (this.showBackButton) {
            this.onTransition(this.transitionType.MoveBackward);
        }
    }

    ngOnInit() {
        if (this.activeLanguage && !this.meta.getTag('http-equiv = content-language')) {
            this.meta.addTag({
                'http-equiv': 'content-language',
                content: this.activeLanguage.value
            });
        }

        this.updateConditionsStream
            .pipe(
                takeUntil(this.componentDestroyed),
                debounceWithFirst(10, 500)
            )
            .subscribe(() => this.transitionClicked.emit(this.transitionType.UpdateConditions))

        this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
        this.sharedService
            .getData(TakeSurveyData.UPDATED_RATING_ITEM_ID)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateConditions(s.data);
            });
        this.sharedService.validationText
            .subscribe(text => (this.validationText = text));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.page != null) {
            this.influencingItems = this.findInfluencingItems(this.page);
        }

        this.getItemsNumeration();
    }

    trackByItemId(index, item) {
        return item.id;
    }

    private findInfluencingItems(page: SurveyPage): number[] {
        const influencingItems: number[] = [];
        page.items.forEach(item => {
            if (item.include_condition) {
                this.addDependency(influencingItems, item.include_condition);
            }
            if (item.rows && item.rows.length) {
                item.rows.forEach(row => {
                    if (row.include_condition) {
                        this.addDependency(influencingItems, row.include_condition);
                    }
                });
            }
            if (item.fields && item.fields.length) {
                item.fields.forEach(field => {
                    if (field.question.include_condition) {
                        this.addDependency(influencingItems, field.question.include_condition);
                    }
                });
            }
        });
        return influencingItems;
    }

    private addDependency(influencingItems: number[], condition) {
        condition.groups.forEach(group => {
            group.expressions.forEach(expression => {
                if (expression.left_operand.operand_type === SurveyExpressionsSourceType.QUESTION) {
                    influencingItems.push(expression.left_operand.item_id);
                }
            });
        });
    }

    // TODO: avoid calculation of item numbers on each change and if numeration is turned off
    // we need to change numeration on page open and after same page conditions check
    getItemsNumeration() {
        if (!this.page.settings || !this.page.settings.first_question_number)
            return;

        if (!this.questionItemTypes.length) {
            for (const key in SurveyQuestionType) {
                if (SurveyQuestionType.hasOwnProperty(key) || EditorSurveyQuestionType.hasOwnProperty(key)) {
                    this.questionItemTypes.push(SurveyQuestionType[key]);
                }
            }
        }

        let i = this.page.settings.first_question_number;
        this.page.items = _.chain(this.page.items)
            .map((item) => {
                if (this.questionItemTypes.includes(item.item_type) && item.enabled && !item.excluded) {
                    item.item_number = i++;
                }
                return item;
            })
            .value();
    }

    get showBackButton(): boolean {
        return this.respondentSettings && this.respondentSettings.allowBack;
    }

    get showResponseResetButton(): boolean {
        return this.respondentSettings && this.respondentSettings.allowReset;
    }

    get isNotFirst(): boolean {
        return _.findIndex(this.pages, x => x.id === this.page.id) !== 1;
    }

    get isNotBeforeLast(): boolean {
        return (
            _.findIndex(this.pages, x => x.id === this.page.id) !==
            this.pages.length - 2
        );
    }

    get isBeforeLast(): boolean {
        return (
            _.findIndex(this.pages, x => x.id === this.page.id) ===
            this.pages.length - 2
        );
    }

    get isLast() {
        return (
            _.findIndex(this.pages, x => x.id === this.page.id) ===
            this.pages.length - 1
        );
    }

    get showSaveButton() {
        return !this.isFromAdmin && this.respondentSettings && this.respondentSettings.allowSave;
    }

    getItemValidationError(item) {
        if (item && item.id) {
            const error = _.find(this.validationMessages, {item_id: item.id}) as ValidationObject;
            if (error) {
                return  error.params ?  String.Format(this.validationText[error.validationMessage], error.params)
                : this.validationText[error.validationMessage];
            }
        }
    }

    updateConditions(newValue) {
        if (_.includes(this.influencingItems, newValue.id)) {
            this.updateConditionsStream.next(this.transitionType.UpdateConditions);
        }
    }

    onUpdate(newValue) {
        const surveyItem = this.page.items.find(x => x.id === newValue.id);
        if (surveyItem) {
            const merged = { ...surveyItem, ...newValue};
            Object.assign(surveyItem, merged);
        }
        this.updateConditions(newValue);
    }

    onMatrixUpdate(newValue) {
        const updatedItem = { ...newValue, id: newValue.cell_id };
        this.updateConditions(updatedItem);
    }

    onContactFormUpdate(newValue) {
        this.updateConditions(newValue);
    }

    getMatrixValidationMessages() {
        return this.validationMessages.filter(
            x => x.type === this.sumTotalError || x.type === this.minMaxError
        );
    }

    onRestart($event) {
        this.restartSurvey.emit($event);
    }

    onResponseReset() {
        this.singleLines.forEach(line => line.resetForm());
        this.multiLines.forEach(line => line.resetForm());
        this.radioButtons.forEach(radioButton => radioButton.resetForm());
        this.checkboxes.forEach(checkbox => checkbox.resetForm());
        this.dropDowns.forEach(dropDown => dropDown.resetForm());
        this.customDropDowns.forEach(customDropDowns => customDropDowns.resetForm());
        this.matrixes.forEach(matrix => matrix.resetForm());
        this.contactForms.forEach(contactForm => contactForm.resetForm());
        this.ratingScales.forEach(dropDown => dropDown.resetForm());
        this.sliders.forEach(slider => slider.resetForm());
        this.maxDiffs.forEach(maxDiff => maxDiff.resetForm());
        this.rankOrders.forEach(rankOrder => rankOrder.resetForm());
        this.ratings.forEach(rating => rating.resetForm());
        this.scores.forEach(score => score.resetForm());
        this.files.forEach(file => file.resetFileInput());
        this.signatures.forEach(signature => signature.resetForm());
        this.imageChoice.forEach(image => image.resetForm());
    }

    onTransition(transitionType) {
        this.transitionClicked.emit(transitionType);
        window.scroll(0, 0);
    }

    saveAndExit() {
        this.onTransition(ResponsePageAction.Save);
    }

    ngOnDestroy() {}
}
