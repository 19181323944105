<mat-form-field>
    <input 
    aria-label="datepicker"
    matInput 
    [ngxMatDatetimePicker]="picker"
    [placeholder]="placeholder"
    [(ngModel)]="dateTime"
    (ngModelChange)="propagateChange($event)"
    [min]="minDate"
    [max]="maxDate"
    (click)="openDatePicker()"
    #pickerRef
    />
    <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
    <ngx-mat-datetime-picker 
    #picker 
    [showSeconds]="false"
       [stepHour]="1" [stepMinute]="1"
       [touchUi]="false" [enableMeridian]="false" 
       [disableMinute]="false" [hideTime]="datepickerMode===dateOnlyMode"
       (closed)="afterPickerClosed()" (opened)="beforePickerOpen()">
    </ngx-mat-datetime-picker>
 </mat-form-field>
