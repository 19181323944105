<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>

        <!-- BEST_WORST_ITEM -->
        <div
            class="cb-max-diff-results cb-question-item-content"
            [ngClass]="{
                'best-worst-item':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.BEST_WORST_ITEM,
                'worst-best-item':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.WORST_BEST_ITEM,
                'item-best-worst':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.ITEM_BEST_WORST,
                'item-worst-best':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.ITEM_WORST_BEST,
                'best-item-worst':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.BEST_ITEM_WORST,
                'worst-item-best':
                    questionItem.orientation ===
                    maxDiffItemOrientationEnum.WORST_ITEM_BEST
            }"
        >
            <form [formGroup]="form" >
                <div formArrayName="sets" role='group' [attr.aria-labelledby]="questionId + questionItem.id">
                    <div
                        class="cb-max-diff-table"
                        *ngFor="let set of sets; let setIndex = index"
                        [formGroupName]="setIndex"
                    >
                        <div class="tr tr-head">
                            <div
                                class="td control-cell best-cell"
                                [id]="'best_label_' + setIndex + '_' + questionItem.id"
                            >
                                {{ questionItem.best_label }}
                            </div>
                            <div
                                class="td control-cell worst-cell"
                                [id]="'worst_label_' + setIndex + '_' + questionItem.id"
                            >
                                {{ questionItem.worst_label }}
                            </div>
                            <div class="td label-cell item-cell">
                                {{ questionItem.item_label }}
                            </div>
                        </div>
                        <ng-container
                            *ngFor="
                                let c of set['controls']['best_choices'].value;
                                let choiceIndex = index
                            "
                        >
                            <div
                                class="tr"
                                *ngIf="
                                    choiceIndex <
                                    questionItem.number_items_per_set
                                "
                            >
                                <div class="td control-cell best-cell">
                                    <mat-radio-button
                                        [value]="c.id"
                                        [aria-label]="questionItem.best_label + ' ' + c.text"
                                        [name]="
                                            questionItem.id +
                                            'best_choices' +
                                            choiceIndex +
                                            setIndex
                                        "
                                        (change)="
                                            onGroupValueChanged(
                                                $event,
                                                setIndex,
                                                'best_choices'
                                            )
                                        "
                                        [checked]="
                                            getCheckedPropertyValue(
                                                choiceIndex,
                                                setIndex,
                                                'best_choices'
                                            )
                                        "
                                    >
                                        <span hidden>
                                            {{ questionItem.best_label }}
                                            {{ c.text }}</span
                                        >
                                    </mat-radio-button>
                                </div>
                                <div class="td control-cell worst-cell">
                                    <mat-radio-button
                                        [value]="c.id"
                                        [aria-label]="questionItem.worst_label + ' ' + c.text"
                                        [name]="
                                            questionItem.id +
                                            'worst_choices' +
                                            choiceIndex +
                                            setIndex
                                        "
                                        (change)="
                                            onGroupValueChanged(
                                                $event,
                                                setIndex,
                                                'worst_choices'
                                            )
                                        "
                                        [checked]="
                                            getCheckedPropertyValue(
                                                choiceIndex,
                                                setIndex,
                                                'worst_choices'
                                            )
                                        "
                                    >
                                        <span hidden>
                                            {{ questionItem.worst_label }}
                                            {{ c.text }}</span
                                        >
                                    </mat-radio-button>
                                </div>
                                <div
                                    class="td label-cell item-cell"
                                    [innerHtml]="c.text | keepHtml"
                                ></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<br />
<br />
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
